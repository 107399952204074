import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useEffect } from 'react';

function ExternalRedirect() {
  useEffect(() => {
    window.location.href = 'https://naklecha.notion.site/a-reinforcement-learning-guide';
  }, []);
  return null;
}

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route 
          path="/reinforcement-learning" 
          element={<ExternalRedirect/>} 
        />
        <Route 
          path="/rl" 
          element={<ExternalRedirect/>} 
        />
        <Route path="/" element={
          <div className="App">
            <header>
                <h3>socials</h3>
                <table>
                  <tr>
                    <th>name</th>
                    <th>link</th>
                  </tr> 
                  <tr>
                    <td>twitter</td>
                    <td><a className='underline' target="_blank" href="https://twitter.com/naklecha">twitter</a></td>
                  </tr>
                  <tr>
                    <td>instagram</td>
                    <td><a className='underline' target="_blank" href="https://instagram.com/naklecha">instagram</a></td>
                  </tr>
                  <tr>
                    <td>github</td>
                    <td><a className='underline' target="_blank" href="https://github.com/naklecha">github</a></td>
                  </tr>
                  <tr>
                    <td>buymeacoffee</td>
                    <td><a className='underline' target="_blank" href="https://www.buymeacoffee.com/naklecha">buymeacoffee</a></td>
                  </tr>
                </table>

                <h3>about me</h3>
                <table>
                  <tr>
                    <td>synthetic data & ml <a target="_blank" href="https://glaive.ai">glaive.ai</a></td>
                  </tr>
                  <tr>
                    <td>i created <a target="_blank" href="https://AAAAAAAAAA.org">aaaaaaaaaa.org</a> with some friends</td>
                  </tr>
                  <tr>
                    <td>my art studio <a target="_blank" href="https://notpink.art">notpink.art</a></td>
                  </tr>
                  <tr> 
                    <td>education -- masters in ai (dropped out)</td>
                  </tr>
                  <tr> 
                    <td>prev -- ex-morgan stanley, trained and finetuned llms</td>
                  </tr>
                </table>

              <h3>technical blogs</h3>
              <table>
                <tr>
                  <td><a className='underline' target="_blank" href="https://naklecha.com/rl">a reinforcement learning guide</a> -- read by 10,000+ people</td>
                </tr>
                <tr>
                  <td><a className='underline' target="_blank" href="https://naklecha.notion.site/explained-latent-consistency-models-13a9290c0fd3427d8d1a1e0bed97bde2?pvs=74">latent consistency models</a> -- read by 8,000+ people</td>
                </tr>
                <tr>
                  <td><a className='underline' target="_blank" href="https://github.com/naklecha/llama3-from-scratch">llama3 explained from scratch</a> -- 10,000+ github stars</td>
                </tr>
              </table>


                <h3>highlights</h3>
                <table>
                  <tr>
                    <td>my <a target="_blank" href="https://github.com/naklecha/llama3-from-scratch">llama3 from scratch</a> repo got 10,000+ github stars</td>
                  </tr>
                  <tr>
                    <td>won $25,000 at buildspace season 4 -- <a target="_blank" href="https://x.com/_buildspace/status/1703504078683377726">tweet</a></td>
                  </tr>
                  <tr>
                    <td>122,000+ users have used my apps (<a target="_blank" href="https://www.naklecha.com/users.png">dashboard ss</a>)</td>
                  </tr>
                  <tr>
                    <td>#1 on hackernews (for 24+ hours) <a href="https://news.ycombinator.com/item?id=40408880" target='_blank'>link</a></td>
                  </tr>
                  <tr>
                    <td>won 7 hackathons in a row in 2022</td>
                  </tr>
                  <tr>
                    <td>rank #11 on hackerank's algorithms leaderboard in 2016 <a href="https://x.com/naklecha/status/765158791989952512" target='_blank'>link</a></td>
                  </tr>
                </table>

                <h3>projects</h3>
                <p>(my best performing projects)</p>
                <table>
                  <tr>
                    <th>link</th>
                    <th>users</th>
                    <th>status</th>
                  </tr>
                  <tr>
                    <td><a className='underline' target="_blank" href="https://stockmusic.app">stockmusic.app</a></td>
                    <td>15,000</td>
                    <td className='green'>alive</td>
                  </tr>
                  <tr>
                    <td><a className='underline' target="_blank" href="https://whatonearth.ai">whatonearth.ai</a></td>
                    <td>40,000</td>
                    <td className='green'>alive</td>
                  </tr>
                  <tr>
                    <td><a className='underline' target="_blank" href="https://fashionai.me">fashionai.me</a></td>
                    <td>31,000</td>
                    <td className='green'>alive</td>
                  </tr>
                  <tr>
                    <td><a className='underline' target="_blank" href="https://copilot.naklecha.com/">google colab copilot</a></td>
                    <td>22,000</td>
                    <td className='red'>dead</td>
                  </tr>
                </table>

                <br></br>
                <img width={150} src="dp.png"></img>
            </header>
          </div>
        }/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
